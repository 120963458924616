import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "../style/RecordingDetails.scss";
import { L } from "../langauge/english.lang";
import { formatDate } from "../services/dateService";
import { API, getAuthHeader } from "../constants/api.const";
import ConfirmationModal from "../component/ConfirmationModal";

const removeQuotes = (str) => str.replace(/['"]+/g, "");

const RecordingDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { recording, isAdmin } = location.state;
  const [saveModal, opensaveModal] = useState(false);

  const [formData, setFormData] = useState({
    description:
      recording?.consultation_summary?.length > 0
        ? recording.consultation_summary[0].summary_text
        : "",
    ZPMCode:
      recording?.consultation_summary?.length > 0
        ? removeQuotes(
            recording.consultation_summary[0].code?.split(",")[0] || ""
          )
        : "",
    GGZNHNCode:
      recording?.consultation_summary?.length > 0
        ? removeQuotes(
            recording.consultation_summary[0].code?.split(",")[1] || ""
          )
        : "",
  });
  const [showModal, setShowModal] = useState(false);

  const handleChange = (e) => {
    // if (recording.is_reviewed) return;
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: removeQuotes(value) });
  };

  const handleSubmit = (e) => {
    opensaveModal(false);
    if (e === false) return;

    const payload = {
      consultation_summaries: [
        {
          ai_model_name: "chatgpt_4",
          summary_text: formData.description,
          codes: `${formData.GGZNHNCode},${formData.ZPMCode}`,
        },
      ],
      is_admin_reviewed: true,
    };

    axios
      .patch(API.UPDATE_RECORDING + recording.id + "/", payload, {
        headers: getAuthHeader(),
      })
      .then((response) => {
        navigate("/admin");
      })
      .catch((error) => {
        toast.error(L.MSG.ERROR_SAVING_CONVERSATION);
        console.error("Error updating recording:", error);
      });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    navigate("/admin");
  };

  return (
    <div className="recording-details">
      <ConfirmationModal
        title={L.SAVE_MODAL.TITLE}
        text={L.SAVE_MODAL.TEXT}
        confirmButtonText={L.SAVE_MODAL.YES}
        cancelButtonText={L.SAVE_MODAL.NO}
        show={saveModal || false}
        handleClose={handleSubmit}
      />
      <div className="detail-container">
        <div className="header-recording">
          <div className="name-box">
            <div className="label">{L.CLIENT}</div>
            <div className="value">
              {recording?.appointment?.patient?.first_name}{" "}
              {recording?.appointment?.patient?.last_name}
            </div>
          </div>
          <div className="date-box">
            <div className="date">
              {formatDate(recording?.appointment?.appointment_time, {
                dateStyle: "medium",
              })}
            </div>
            <div className="time">
              {formatDate(recording?.appointment?.appointment_time, {
                timeStyle: "short",
              })}
            </div>
          </div>
        </div>

        <div className="content">
          <div className="header-recording">{L.NOTE}</div>
          <textarea
            name="description"
            className="text-box"
            value={formData.description}
            onChange={handleChange}
            // readOnly={recording.is_reviewed}
          ></textarea>
        </div>

        {isAdmin && (
          <div className="footer">
            <div className="box">
              <div className="label">GGZ-NHN Code</div>
              <input
                type="text"
                name="GGZNHNCode"
                className="text"
                value={formData.GGZNHNCode}
                onChange={handleChange}
              />
            </div>
            <div className="box">
              <div className="label">ZPM Code</div>
              <input
                type="text"
                name="ZPMCode"
                className="text"
                value={formData.ZPMCode}
                onChange={handleChange}
              />
            </div>
            {recording.is_reviewed && (
              <div className="submit-container">
                <button
                  onClick={() => opensaveModal(true)}
                  className="submit-button"
                >
                  {L.SAVE}
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>{L.RECORDING_REVIEWED_SUCCESSFULLY}</h2>
            <p>{L.REDIRECTING_TO_ADMIN_PAGE}</p>
            <button onClick={handleCloseModal} className="modal-button">
              {L.OK}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RecordingDetails;
