import React, { useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./../style/change-password.css"; // Assuming this contains similar styles to the example image
import { createAdminUser } from "./../services/userService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const AddUser = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const userSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    gender: yup.string().required("Gender is required"),
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        "Password must contain at least one special character"
      )
      .required("Password is required"),
    confirm_password: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  // Handle user creation
  const handleUserSubmit = (data) => {
    createAdminUser(data);
  };

  return (
    <section className="d-flex justify-content-center align-items-center change-password__container ">
      <div className="p-4 card" style={{ width: "60%" }}>
        <h3 className="text-center mb-4">Add User</h3>
        <Formik
          validationSchema={userSchema}
          onSubmit={handleUserSubmit}
          initialValues={{
            email: "",
            first_name: "",
            last_name: "",
            gender: "",
            password: "",
            confirm_password: "",
          }}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ handleSubmit, handleChange, values, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <div className="d-flex gap-3">
                <Form.Group controlId="first_name" className="mb-3 w-50">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="First Name"
                    name="first_name"
                    value={values.first_name}
                    onChange={handleChange}
                    isInvalid={!!errors.first_name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.first_name}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="last_name" className="mb-3 w-50">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Last Name"
                    name="last_name"
                    value={values.last_name}
                    onChange={handleChange}
                    isInvalid={!!errors.last_name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.last_name}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="d-flex gap-3">
                <Form.Group controlId="email" className="mb-3 w-50">
                  <Form.Label>Enter Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="gender" className="mb-3 w-50">
                  <Form.Label>Gender</Form.Label>
                  <Form.Control
                    as="select"
                    name="gender"
                    value={values.gender}
                    onChange={handleChange}
                    isInvalid={!!errors.gender}
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.gender}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="d-flex gap-3">
                <Form.Group controlId="password" className="mb-3 w-50">
                  <Form.Label>Choose Password</Form.Label>
                  <div style={{ position: "relative" }}>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      isInvalid={!!errors.password}
                    />
                    <FontAwesomeIcon
                      onClick={() => setShowPassword(!showPassword)}
                      icon={showPassword ? faEyeSlash : faEye}
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                        color: "#6c757d",
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>

                <Form.Group controlId="confirm_password" className="mb-3 w-50">
                  <Form.Label>Confirm Password</Form.Label>
                  <div style={{ position: "relative" }}>
                    <Form.Control
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Confirm password"
                      name="confirm_password"
                      value={values.confirm_password}
                      onChange={handleChange}
                      isInvalid={!!errors.confirm_password}
                    />
                    <FontAwesomeIcon
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      icon={showConfirmPassword ? faEyeSlash : faEye}
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                        color: "#6c757d",
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.confirm_password}
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </div>

              <Button
                type="submit"
                variant="primary"
                className="w-100 mt-3 bg-blue"
              >
                Create User
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
};

export default AddUser;
