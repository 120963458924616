import axios from "axios";
import { API, getAuthHeader } from "../constants/api.const"; // Adjust the path based on your project structure
import { toast } from "react-toastify";
import { USER_ID_KEY } from "../constants/key.const";

export const changePassword = async (data) => {
  try {
    await axios.post(
      API.CHANGE_PASSWORD,
      {
        current_password: data.currentPassword,
        new_password: data.newPassword,
        confirm_password: data.confirm_password,
      },
      { headers: getAuthHeader() }
    );
    toast.success("Password changed successfully");
  } catch (error) {
    toast.error("Error happened while changing password");
    console.error("Error happened while changing password");
  }
};

export const getAllUsers = async () => {
  try {
    const res = await axios.get(API.GET_ALL_USERS, {
      headers: getAuthHeader(),
    });
    return res.data.data;
  } catch (error) {
    toast.error("Error happened while fetching users");
    console.error("Error happened while fetching users");
  }
};

export const createAdminUser = async (data) => {
  try {
    await axios.post(
      API.SIGNUP_ADMIN,
      { ...data },
      {
        headers: getAuthHeader(),
      }
    );
    toast.success("User created successfully");
  } catch (error) {
    toast.error("Error happened while creating user");
    console.error("Error happened while creating user");
  }
};

export const createAppointment = async (data) => {
  try {
    await axios.post(
      API.APPOINTMENT_ADMIN,
      { ...data },
      {
        headers: getAuthHeader(),
      }
    );
    toast.success("Appointment created successfully");
  } catch (error) {
    toast.error("Error happened while creating appointment");
    console.error("Error happened while creating appointment");
  }
};

export const deleteUser = async () => {
  const userId = sessionStorage.getItem(USER_ID_KEY);
  try {
    await axios.delete(`${API.UPDATE_PROFILE}${userId}/`, {
      headers: getAuthHeader(),
    });
  } catch (error) {
    console.error("Error deleting user", error);
    toast.error("Error deleting user");
  }
};
