import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import "../style/sidebar.scss";
import logo from "../images/logo.png";
import {
  faHome,
  faClipboardList,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NAME_KEY } from "../constants/key.const";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { L } from "../langauge/english.lang";
import { useAuth } from "../contexts/AuthContext";
import ConfirmationModal from "./ConfirmationModal";
import { deleteUser } from "../services/userService";
import { logout } from "../services/authService";
import { SlidingNav } from "./SlidingNav";
import { X } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";

const SideBar = ({ privacyMode, setPrivacyMode, page, setPage }) => {
  const navigate = useNavigate();
  // const [privacyMode, setPrivacyMode] = useState(true);
  const { logoutUser } = useAuth();
  const name = sessionStorage.getItem(NAME_KEY);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const isSuperUser = sessionStorage.getItem("SUPERUSER") === "true";

  const getGreeting = () => {
    const currentHour = new Date().getHours(); // Get the current hour (0-23)

    if (currentHour >= 3 && currentHour < 12) {
      return L.GREETING_MORNING;
    } else if (currentHour >= 12 && currentHour < 18) {
      return L.GREETING_AFTER_NOON;
    } else {
      return L.GREETING_EVENING;
    }
  };

  const handleDeleteAccount = () => {
    setShowModal(true);
    handleClose();
  };

  // const links = [
  //   { path: "/home", label: L.LINK.START, icon : faHome },
  //   // { path: "/agenda", label: "Agenda" },
  //   { path: "/overview", label: L.LINK.OVERVIEW, icon : faClipboardList },
  //   // { path: "/admin", label : L.LINK.ADMIN, icon : faUser },
  //   { path: "/admin", label: L.LINK.ADMIN, icon: faUser, superUserOnly: true },
  // ];

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [links, setLinks] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Define links based on screen width
    let updatedLinks = [{ path: "/home", label: L.LINK.START, icon: faHome }];

    // Add additional links for non-mobile screens
    if (screenWidth > 500) {
      // 768px is a common breakpoint for mobile screens
      updatedLinks.push({
        path: "/overview",
        label: L.LINK.OVERVIEW,
        icon: faClipboardList,
      });

      if (isSuperUser) {
        updatedLinks.push({
          path: "/admin",
          label: L.LINK.ADMIN,
          icon: faUser,
        });
      }
    }

    setLinks(updatedLinks);
  }, [screenWidth, isSuperUser]);
  return (
    <>
      <div className="flex h-screen w-full relative">
        {/* <div className="sticky left-0 top-0 w-[300px] shadow-[3px_0px_12px_whitesmoke] z-10 bg-white justify-between h-full border-r border-[#d1d1d1] flex-col hidden lg:flex pb-[18px]">
          <div>
            <div className="flex justify-center">
              <img src={logo} alt="Logo" className="h-[86px] border-b-2" />
            </div>
            <div className="flex flex-col gap-4 mt-[50px] w-full p-[12px]">
              {links.map((link, i) => (
                <NavLink
                  key={i}
                  className={({ isActive }) =>
                    `rounded p-3 flex justify-start text-black gap-[13px] items-center ${
                      isActive
                        ? "bg-[#3666e913] text-[rgb(0,66,207)]"
                        : "text-gray-500"
                    }`
                  }
                  to={link.path}
                  onClick={() => setPage(link.label)}
                >
                  <FontAwesomeIcon
                    icon={link.icon}
                    className={`text-[1.1em] ${({ isActive }) =>
                      isActive ? "text-[rgb(0,66,207)]" : "text-gray-500"}`}
                  />
                  {link.label}
                </NavLink>
              ))}
            </div>
          </div>
          <div>
            <Toggle
              label={L.PRIVACY_MODE}
              toggled={privacyMode}
              onClick={(e) => setPrivacyMode(e)}
            />
          </div>
        </div> */}
        <SlidingNav
          links={links}
          privacyMode={privacyMode}
          setPrivacyMode={setPrivacyMode}
          setPage={setPage}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
        <div className="w-full">
          <div className="h-[76px] shadow-[0px_2px_3px_#bdbdbd] w-full flex space-x-4 px-6 py-[42px] items-center border-b-2">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className=" xl:hidden bg-white rounded-md"
            >
              {isOpen ? <X size={24} /> : <MenuIcon size={24} />}
            </button>
            <div className="flex w-full h-full items-center justify-between">
              <div className="text-base md:text-2xl font-bold text-[#00227d]">
                {page}
              </div>
              <div className="flex items-center justify-end gap-5">
                {isSuperUser && page === L.LINK.ADMIN && (
                  <div className="flex gap-2">
                    <button
                      onClick={() => navigate("/add-user")}
                      className="px-3 py-2 bg-[#3667e9] text-white rounded cursor-pointer"
                    >
                      Add User
                    </button>
                    <button
                      onClick={() => navigate("/add-appointment")}
                      className="px-3 py-2 bg-[#3667e9] text-white rounded cursor-pointer"
                    >
                      Add Appointment
                    </button>
                  </div>
                )}
                <div
                  className="flex gap-1 md:gap-3 items-center cursor-pointer w-[75%]"
                  onClick={handleClick}
                >
                  <div className="text-[0.65rem] md:text-sm">
                    {getGreeting()}, {name}
                  </div>
                  <div className="p-2 rounded-xl bg-[#3666e917] text-darkblue">
                    <PermIdentityOutlinedIcon />
                  </div>
                </div>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={logoutUser}
                    style={{ background: "#ffffff" }}
                  >
                    <LogoutOutlinedIcon
                      style={{ color: "#3667E9", marginRight: "8px" }}
                    />
                    <span style={{ color: "#0B1423" }}>{L.LOGOUT}</span>
                  </MenuItem>
                  <MenuItem
                    onClick={() => navigate("/change-password")}
                    style={{ background: "#ffffff" }}
                  >
                    <span style={{ color: "#0B1423" }}>Wachtwoord wijzigen</span>
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>
          <Outlet />
        </div>
        <ConfirmationModal
          text="Are you sure want to delete your account ?"
          show={showModal}
          title="Alert"
          handleClose={(confirmed) => {
            setShowModal(false);
            if (confirmed) {
              deleteUser();
              logoutUser();
              navigate("/sign-in");
            }
          }}
        />
      </div>
    </>
  );
};

export default SideBar;
