import axios from "axios";
import { API, getAuthHeader } from "../constants/api.const";
import { toast } from "react-toastify";

export const deleteAppointment = async (id) => {
  try {
    await axios.delete(`${API.POST_APPOINTMENT}${id}/`, {
      headers: getAuthHeader(),
    });
    toast.success("Appointment Deleted Successfuly");
  } catch (error) {
    console.error("Error deleting appointment", error);
    toast.error("Error deleting appointment");
  }
};

export const deleteConsultation = async (id) => {
  try {
    await axios.delete(`${API.POST_RECORDING}${id}/`, {
      headers: getAuthHeader(),
    });
    toast.success("Recording Deleted Successfuly");
  } catch (error) {
    console.error("Error deleting recording", error);
    toast.error("Error deleting recording");
  }
};
