import React, { useEffect, useState } from "react";
import "../style/auth.scss";
import * as formik from "formik";
import * as yup from "yup";
import Form from "react-bootstrap/Form";
import { NavLink, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { useAuth } from "../contexts/AuthContext";
import { TOKEN_KEY } from "../constants/key.const";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const SignIn = () => {
  const { Formik } = formik;
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const { loginUser } = useAuth();
  const [showPassword, setShowPassword] = useState(false);

  const schema = yup.object().shape({
    email: yup.string().required("Email is required"),
    password: yup.string().required("Password is required"),
    code: yup.string(),
  });

  useEffect(() => {
    const token = sessionStorage.getItem(TOKEN_KEY);
    if (token) {
      navigate("/home");
    }
  }, [navigate]);
  const handleSubmitData = async (data, { resetForm }) => {
    const { email, password, code } = data;
    const result = await loginUser(email, password, code);
    if (result.success) {
      resetForm();
      navigate("/");
    } else {
      setErrorMessage(result.message || "Invalid email or password");
    }
  };
  return (
    <section className="main-auth">
      <div className="main-auth-form">
        <h3>Sign In</h3>
        <p className="mb-0">
          Don't have an account?
          <NavLink to="/sign-up">&nbsp; Sign Up</NavLink>
        </p>
        <Formik
          validationSchema={schema}
          onSubmit={handleSubmitData}
          initialValues={{
            email: "",
            password: "",
            code: "",
          }}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit} className="mt-4 py-1">
              <Form.Group controlId="email">
                <Form.Control
                  type="email"
                  placeholder="Email address"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  isInvalid={!!errors.email}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="password" className="mt-4">
                <div style={{ position: "relative" }}>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    isInvalid={!!errors.password}
                  />
                  <FontAwesomeIcon
                    onClick={() => setShowPassword(!showPassword)}
                    icon={showPassword ? faEyeSlash : faEye}
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                      color: "#6c757d",
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
              <Form.Group controlId="otpToken" className="mt-4">
                <Form.Control
                  type="otpToken"
                  placeholder="Authenticator Code"
                  name="email"
                  value={values.code}
                  onChange={handleChange}
                  isInvalid={!!errors.code}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.code}
                </Form.Control.Feedback>
              </Form.Group>
              {errorMessage && (
                <div className="text-danger text-center mt-3">
                  {errorMessage}
                </div>
              )}
              <div className="mt-3">
                <NavLink to="/forgot-password">Forgot Password?</NavLink>
              </div>

              <Button
                type="submit"
                variant="primary"
                className="w-100 mt-4 auth-button"
              >
                Sign In
              </Button>
              {/* <div className="or">Or</div>

              <MicrosoftAuthComponent /> */}

              <hr />

              <div className="message">
                Protected by reCAPTCHA and subjected to Rhombus{" "}
                <NavLink>Privacy Policy </NavLink> and{" "}
                <NavLink>Terms of Service.</NavLink>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
};

export default SignIn;
