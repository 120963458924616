// // import React, { useState, useEffect } from 'react';
// // import Button from 'react-bootstrap/Button';
// // import Dropdown from 'react-bootstrap/Dropdown';
// // import { useLocation, useNavigate } from 'react-router-dom';
// // import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
// // import { MarginRounded } from '@mui/icons-material';
// // import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
// // import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// // import { L } from '../langauge/english.lang';
// // import { API, AUTH_HEADER } from '../constants/api.const';
// // import axios from 'axios';
// // const Step5 = ({setActiveStep, setPage}) => {
// //     const [value, onChange] = useState(new Date());
// //     const [model, setModel] = useState('CHATGPT 4');
// //     const [dropdownOpen, setDropdownOpen] = useState(false);
// //     const [clientNote, setClientNote] = useState("chatgpt")
// //     const location = useLocation()
// //     const {state} = location
// //     console.log(state)
// //     const navigate = useNavigate()
// //     setPage(L.WRAP_UP)

// //     const [modelData, setModelData] = useState(state?.modelData)

// //     const handleModel = (type) => {
// //         setModel(type);
// //         let modelData = state?.modelData
// //         if (modelData) {
// //             if (type === "CHATGPT 4") {
// //                 setClientNote(modelData.gpt4oSummary);
// //             } else if (type === "Custom-Model") {
// //                 setClientNote(modelData.llama3Summary);
// //             } else {
// //                 setClientNote("Here, you can write your summary");
// //             }
// //         }
// //         setDropdownOpen(false);
// //     };

// //     const copyToClipboard = (cut = false) => {
// //         navigator.clipboard.writeText(clientNote).then(() => {
// //             if(cut===true) setClientNote("")
// //         }).catch(err => {
// //         });
// //       };

// //       const markReviewed = () =>{
// //         copyToClipboard()
// //         const recordingId = state.recordingId
// //         if (!recordingId) return;
// //     axios.patch(API.UPDATE_RECORDING+recordingId+"/", {is_reviewed : true}, AUTH_HEADER).then((res)=>{
// //     //   if(res.status==200) toast.success(L.MSG.RECORDING_DELETED);
// //         navigate("/home")
// //     }).catch(e=>{
// //       console.log(e)
// //     })
// //     }

// //     useEffect(() => {
// //         handleModel(model);
// //     }, [model]);

// //     return (

// //             <div>
// //                 {/* <div className='d-flex justify-content-between mb-4 align-items-center'>
// //                     <h4 className='right-col-title '>
// //                         Review
// //                     </h4>
// //                     <Dropdown
// //                         show={dropdownOpen}
// //                         onToggle={() => setDropdownOpen(!dropdownOpen)}

// //                     >
// //                         <Dropdown.Toggle id="dropdown-basic"  style={{
// //                             backgroundColor : '#7F7F7F',
// //                             border : 'none'
// //                         }}>
// //                             {model}
// //                         </Dropdown.Toggle>
// //                         <Dropdown.Menu >
// //                             <li class="dropdown-item" onClick={() => handleModel("CHATGPT 4")}>CHATGPT 4</li>
// //                             <li class="dropdown-item" onClick={() => handleModel("Custom-Model")}>Custom-Model</li>
// //                             <li class="dropdown-item" onClick={() => handleModel("Write your own")}>Write your own</li>
// //                         </Dropdown.Menu>
// //                     </Dropdown>
// //                 </div> */}
// //                 <div className='tab-wraper'>

// //                         <div style={{
// //                             borderRadius : '8px',
// //                             border : '1px solid #dddddd'
// //                         }} >
// //                             <div style={{
// //                             backgroundColor : '#3667E9bb',
// //                             color : 'white',
// //                             fontSize : '18px',
// //                             padding : '12px 8px',
// //                             textAlign : 'center',
// //                             borderRadius : '8px 8px 0px 0px'
// //                         }}> {L.FINAL_NOTE} </div>
// //                             <div>
// //                                 <div style={{
// //                                     width:'100%',
// //                                     height : 'calc(100vh - 270px)',
// //                                     resize : 'none',
// //                                     minHeight : '300px',
// //                                     border : 'none',
// //                                     padding : '3px'
// //                                 }}
// //                                  className='content' > {clientNote}  </div>
// //                             </div>
// //                         </div>
// //                 </div>
// //                 <div className='footer'>
// //                 <KeyboardBackspaceIcon  className='footer-button'   onClick={e => {setActiveStep(2); navigate('/home/walkthrough/review', {state : {...state}})}}/>
// //                 <div  onClick={markReviewed}
// //                 style={{
// //                     background:'#DCDCDC',
// //                     color : '#3667E9',
// //                     borderRadius : '6px',
// //                     display : 'flex',
// //                     justifyContent : 'center',
// //                     alignItems : 'center',
// //                     padding : '6px 8px ',
// //                     cursor : 'pointer',
// //                     gap : '6px'
// //                 }} > {L.READY_FOR_EHR} <ContentCopyIcon
// //                 style={{
// //                     color : 'white',
// //                     background : '#3667E9',
// //                     boxSizing : 'unset',
// //                     borderRadius : '50%',
// //                     padding  :'6px'
// //                 }}/></div>
// //                 </div>
// //                 {/* <div className='mt-4 d-flex justify-content-between'>
// //               <div style={{
// //                 cursor : 'pointer'
// //               }} onClick={e=>navigate('/home/summary')}>
// //               <Button style={{
// //                         backgroundColor : '#3667E9',
// //                         color : 'white',
// //                         fontSize : '16px',
// //                         borderRadius : '8px',
// //                         padding : '0px',
// //                         marginRight : '8px'
// //                     }}   > <KeyboardArrowLeftIcon/> </Button>
// //                     Back
// //               </div>

// // <Button style={{
// //                         backgroundColor : '#3667E9',
// //                         color : 'white',
// //                         fontSize : '16px',
// //                         borderRadius : '5px',
// //                         padding : '12px 30px',
// //                         display : 'flex',
// //                         justifyContent : 'center',
// //                         alignItems : 'center',
// //                         gap : '12px'
// //                     }}  onClick={copyToClipboard} > Copy</Button>

// //                 </div> */}
// //             </div>
// //     )
// // }

// // export default Step5;

// import React, { useState, useEffect } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
// import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// import { L } from '../langauge/english.lang';
// import { API, AUTH_HEADER } from '../constants/api.const';
// import axios from 'axios';
// import { toast } from 'react-toastify';

// const Step5 = ({setActiveStep, setPage}) => {
//     const [clientNote, setClientNote] = useState("");
//     const location = useLocation();
//     const { state } = location;
//     const navigate = useNavigate();
//     setPage(L.WRAP_UP);

//     useEffect(() => {
//         const fetchRecordingData = async () => {
//             try {
//                 const response = await axios.get(`${API.UPDATE_RECORDING}${state.recordingId}/`, AUTH_HEADER);
//                 const summaries = response.data.data.consultation_summary;
//                 const chatGPT4Summary = summaries.find(summary => summary.ai_model_name === "llama3");
//                 if (chatGPT4Summary) {
//                     setClientNote(chatGPT4Summary.summary_text);
//                 } else {
//                     setClientNote("No ChatGPT 4 summary available.");
//                 }
//             } catch (error) {
//                 toast.error("Error fetching recording data");
//                 console.error("Error fetching recording data:", error);
//             }
//         };

//         fetchRecordingData();
//     }, [state.recordingId]);

//     const copyToClipboard = () => {
//         navigator.clipboard.writeText(clientNote).then(() => {
//             toast.success("Summary copied to clipboard");
//         }).catch(err => {
//             toast.error("Failed to copy summary");
//             console.error("Failed to copy text: ", err);
//         });
//     };

//     const markReviewed = () => {
//         copyToClipboard();
//         const recordingId = state.recordingId;
//         if (!recordingId) return;
//         axios.patch(`${API.UPDATE_RECORDING}${recordingId}/`, {is_reviewed: true}, AUTH_HEADER)
//             .then((res) => {
//                 if (res.status === 200) {
//                     toast.success(L.MSG.RECORDING_REVIEWED);
//                     navigate("/home");
//                 }
//             }).catch(e => {
//                 toast.error("Error marking as reviewed");
//                 console.error("Error marking as reviewed:", e);
//             });
//     };

//     return (
//         <div>
//             <div className='tab-wraper'>
//                 <div style={{
//                     borderRadius: '8px',
//                     border: '1px solid #dddddd'
//                 }}>
//                     <div style={{
//                         backgroundColor: '#3667E9bb',
//                         color: 'white',
//                         fontSize: '18px',
//                         padding: '12px 8px',
//                         textAlign: 'center',
//                         borderRadius: '8px 8px 0px 0px'
//                     }}>{L.FINAL_NOTE}</div>
//                     <div>
//                         <div style={{
//                             width: '100%',
//                             height: 'calc(100vh - 270px)',
//                             resize: 'none',
//                             minHeight: '300px',
//                             border: 'none',
//                             padding: '3px',
//                             overflowY: 'auto'
//                         }}
//                         className='content'>{clientNote}</div>
//                     </div>
//                 </div>
//             </div>
//             <div className='footer'>
//                 <KeyboardBackspaceIcon
//                     className='footer-button'
//                     onClick={() => {setActiveStep(2); navigate('/home/walkthrough/review', {state: {...state}});}}
//                 />
//                 <div
//                     onClick={markReviewed}
//                     style={{
//                         background: '#DCDCDC',
//                         color: '#3667E9',
//                         borderRadius: '6px',
//                         display: 'flex',
//                         justifyContent: 'center',
//                         alignItems: 'center',
//                         padding: '6px 8px',
//                         cursor: 'pointer',
//                         gap: '6px'
//                     }}
//                 >
//                     {L.READY_FOR_EHR}
//                     <ContentCopyIcon
//                         style={{
//                             color: 'white',
//                             background: '#3667E9',
//                             boxSizing: 'unset',
//                             borderRadius: '50%',
//                             padding: '6px'
//                         }}
//                     />
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Step5;

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CircularProgress from "@mui/material/CircularProgress";
import { L } from "../langauge/english.lang";
import { API, getAuthHeader } from "../constants/api.const";
import axios from "axios";
import { toast } from "react-toastify";

const Step5 = ({ setActiveStep, setPage }) => {
  const [clientNote, setClientNote] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  setPage(L.WRAP_UP);

  useEffect(() => {
    const fetchRecordingData = async () => {
      try {
        const response = await axios.get(
          `${API.UPDATE_RECORDING}${state.recordingId}/`,
          {
            headers: getAuthHeader(),
          }
        );
        const summaries = response.data.data.consultation_summary;
        const chatGPT4Summary = summaries.find(
          (summary) => summary.ai_model_name === "llama3"
        );
        if (chatGPT4Summary) {
          setClientNote(chatGPT4Summary.summary_text);
        } else {
          setClientNote("No ChatGPT 4 summary available.");
        }
      } catch (error) {
        toast.error("Error fetching recording data");
        console.error("Error fetching recording data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRecordingData();
  }, [state.recordingId]);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(clientNote)
      .then(() => {
        toast.success("Summary copied to clipboard");
      })
      .catch((err) => {
        toast.error("Failed to copy summary");
        console.error("Failed to copy text: ", err);
      });
  };

  const markReviewed = () => {
    copyToClipboard();
    const recordingId = state.recordingId;
    if (!recordingId) return;
    axios
      .patch(
        `${API.UPDATE_RECORDING}${recordingId}/`,
        { is_reviewed: true },
        {
          headers: getAuthHeader(),
        }
      )
      .then((res) => {
        if (res.status === 200) {
          toast.success(L.MSG.RECORDING_REVIEWED);
          navigate("/home");
        }
      })
      .catch((e) => {
        toast.error("Error marking as reviewed");
        console.error("Error marking as reviewed:", e);
      });
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <div className="tab-wraper">
        <div
          style={{
            borderRadius: "8px",
            border: "1px solid #dddddd",
          }}
        >
          <div
            style={{
              backgroundColor: "#3667E9bb",
              color: "white",
              fontSize: "18px",
              padding: "12px 8px",
              textAlign: "center",
              borderRadius: "8px 8px 0px 0px",
            }}
          >
            {L.FINAL_NOTE}
          </div>
          <div>
            <div
              style={{
                width: "100%",
                height: "calc(100vh - 270px)",
                resize: "none",
                minHeight: "300px",
                border: "none",
                padding: "3px",
                overflowY: "auto",
              }}
              className="content"
            >
              {clientNote}
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <KeyboardBackspaceIcon
          className="footer-button"
          onClick={() => {
            setActiveStep(2);
            navigate("/home/walkthrough/review", { state: { ...state } });
          }}
        />
        <div
          onClick={markReviewed}
          style={{
            background: "#DCDCDC",
            color: "#3667E9",
            borderRadius: "6px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "6px 8px",
            cursor: "pointer",
            gap: "6px",
          }}
        >
          {L.READY_FOR_EHR}
          <ContentCopyIcon
            style={{
              color: "white",
              background: "#3667E9",
              boxSizing: "unset",
              borderRadius: "50%",
              padding: "6px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Step5;
