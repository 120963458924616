// import React, { useEffect, useState } from 'react'
// import axios from "axios";
// import { API, AUTH_HEADER } from "../constants/api.const";
// import EditNoteIcon from "@mui/icons-material/EditNote";
// import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";

// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
// import "../style/agenda.scss";
// import { L } from '../langauge/english.lang';
// import { useNavigate } from 'react-router-dom';

// function Agenda({privacyMode}) {

//   const [agenda, setAgenda] = useState([]);

//   // const formatDate = (dateString, options) => {
//   //   const date = new Date(dateString);
//   //   return new Intl.DateTimeFormat("en-In", options).format(date);
//   // };
//   const formatDate = (dateString, options = {}) => {
//     const date = new Date(dateString);
//     if (options.shortFormat) {
//       const days = ['Sun.', 'Mon.', 'Tues.', 'Wed.', 'Thurs.', 'Fri.', 'Sat.'];
//       const months = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'];

//       const day = days[date.getDay()];
//       const month = months[date.getMonth()];

//       return `${day} ${month} ${date.getDate()} ${date.getFullYear()}`;
//     }
//     return new Intl.DateTimeFormat("en-In", options).format(date);
//   };

//   const formatDateForAPI = (dateString) => {
//     const date = new Date(dateString);
//     return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`
//   };

//   const [selectedDate, setSelectedDate] = useState(new Date())
//   const navigate = useNavigate()

//   // const edit = (apt) =>{
//   //   navigate("/home/walkthrough/record", { state: { apt: {id : apt.id, client_name : apt.patient.first_name+" "+apt.patient.last_name} } });
//   // }
//   const edit = (apt) => {
//     if (apt.is_appointment) {
//       navigate("/home/walkthrough/record", { state: { apt: {id : apt.id, client_name : apt.patient.first_name + (apt.patient.last_name ? " " + apt.patient.last_name : "")} } });
//     }
//   }

//   useEffect(() => {
//       axios
//         .get(API.GET_APPOINTMENTS + `?is_scheduled=true`, AUTH_HEADER)
//         .then((res) => {
//           if (res && res.data) setAgenda(res.data.data);
//         })
//         .catch((e) => console.log(e));
//   }, [selectedDate]);
//   return (
//     <div className='agenda agenda-main' >
//         <div className="left">
//          <div className="head">
//          {L.TODAY_CONSULTATION} <span className="today-date">- {formatDate(new Date(), { shortFormat: true })}</span>
//          {/* <h3 className='date'> {formatDate(selectedDate.toISOString(), {weekday : 'long' })}, {formatDate(selectedDate.toISOString(), {dateStyle : 'medium' })}  </h3> */}
//          </div>
//          {agenda.length>0?( <div className="list">
//             {agenda.map((item) => (
//               <div className="item" onClick={e=>edit(item)}>
//                 <div className="seg1">
//                 <div className="time">
//                     {formatDate(item.appointment_time, {
//                       timeStyle: "short",
//                     })}
//                   </div>
//                 </div>
//                 <div className="seg2">
//                 <div className='top'>
//                 <div className="name">
//                     {/* {item.patient.first_name+" "+item.patient.last_name} */}
//                     {item.patient.first_name + (item.patient.last_name ? " " + item.patient.last_name : "")}
//                 </div>
//                 {/* <MoreHorizIcon className='icon'/> */}
//                 </div>
//                   <div className="desc">

//                   {item.description}
//                   </div>

//                 </div>
//               </div>
//             ))}
//           </div>):(
//             <div className='norecord'> {L.NO_CONSULTATION} </div>
//           )}

//       </div>

//       {/* <div className="right">
//       <LocalizationProvider dateAdapter={AdapterDayjs} className='cal'>
//       <DateCalendar onChange={e=>setSelectedDate(e)} />
//     </LocalizationProvider>
//       </div> */}
//     </div>
//   )
// }

// export default Agenda

import React, { useEffect, useState } from "react";
import axios from "axios";
import { API, getAuthHeader } from "../constants/api.const";
import "../style/agenda.scss";
import { L } from "../langauge/english.lang";
import { useNavigate } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteAppointment } from "../services/dataService";
import ConfirmationModal from "../component/ConfirmationModal";
import { SUPER_USER } from "../constants/key.const";

const Agenda = React.memo(
  ({ privacyMode, selectedDate, agenda, refreshAgenda }) => {
    // const [agenda, setAgenda] = useState([]);
    const navigate = useNavigate();
    const [hoveredItemId, setHoveredItemId] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const handleMouseEnter = (id) => setHoveredItemId(id);
    const handleMouseLeave = () => setHoveredItemId(null);

    const formatDate = (dateString, options = {}) => {
      const date = new Date(dateString);
      if (options.shortFormat) {
        const days = [
          "zondag", // Sunday
          "maandag", // Monday
          "dinsdag", // Tuesday
          "woensdag", // Wednesday
          "donderdag", // Thursday
          "vrijdag", // Friday
          "zaterdag", // Saturday
        ];
        const months = [
          "januari", // January
          "februari", // February
          "maart", // March
          "april", // April
          "mei", // May
          "juni", // June
          "juli", // July
          "augustus", // August
          "september", // September
          "oktober", // October
          "november", // November
          "december", // December
        ];

        const day = days[date.getDay()];
        const month = months[date.getMonth()];
        return `${day} ${date.getDate()} ${month} ${date.getFullYear()}`;
      }
      return new Intl.DateTimeFormat("nl-NL", options).format(date);
    };

    const formatDateForAPI = (dateString) => {
      const date = new Date(dateString);
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    };
    const formatTime = (dateString) => {
      const date = new Date(dateString);

      // Use Intl.DateTimeFormat with timeZone set to 'UTC' to prevent conversion
      return new Intl.DateTimeFormat("nl-NL", {
        timeStyle: "short",
        timeZone: "UTC",
        hourCycle: "h23",
      }).format(date);
    };

    const edit = (apt) => {
      if (apt.is_appointment) {
        navigate("/home/walkthrough/record", {
          state: {
            apt: {
              id: apt.id,
              client_name:
                apt.patient.first_name +
                (apt.patient.last_name ? " " + apt.patient.last_name : ""),
            },
          },
        });
      }
    };
    const dt = new Date();
    const today = `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`;
    // useEffect(() => {
    //   const fetchAgenda = () => {
    //     axios
    //       .get(API.GET_APPOINTMENTS + `?is_scheduled=true&date=${selectedDate}`, {
    //         headers: getAuthHeader(),
    //       })
    //       .then((res) => {
    //         if (res && res.data) setAgenda(res.data.data);
    //       })
    //       .catch((e) => console.log(e));
    //   };

    //   fetchAgenda();
    //   const intervalId = setInterval(fetchAgenda, 60000);
    //   return () => clearInterval(intervalId);
    // }, [selectedDate]);

    const handleDeleteConfirmation = (id, e) => {
      e.stopPropagation();
      setSelectedId(id);
      setShowModal(true);
    };

    const handleDeleteAppointment = async (id) => {
      await deleteAppointment(id);
      refreshAgenda();
    };

    return (
      <div className="flex justify-start h-[calc(100vh-64px)] w-full flex-wrap">
        <div className="w-full max-w-[95%] flex flex-col gap-2.5 h-[90%] bg-white rounded-xl overflow-x-auto relative">
          <div className="sticky top-0 bg-white font-bold text-base md:text-2xl z-10">
            {L.TODAY_CONSULTATION}
            <span className="text-sm font-medium ml-2.5 text-[#656565]">
              - {formatDate(selectedDate, { shortFormat: true })}
            </span>
          </div>
          {agenda.length > 0 ? (
            <div className="mt-3 flex flex-col gap-2 h-full overflow-y-auto">
              {agenda.map((item) => (
                <div
                  key={item.id}
                  className={`rounded p-2 px-4 flex justify-between items-center ${
                    item.is_appointment
                      ? "cursor-pointer hover:bg-[#E0E0E0]"
                      : ""
                  } bg-[#F7F7F7]`}
                  onClick={() =>
                    item.is_appointment &&
                    item.status !== "processing" &&
                    edit(item)
                  }
                  onMouseEnter={() => handleMouseEnter(item.id)}
                  onMouseLeave={handleMouseLeave}
                >
                  <div className="flex items-center space-x-2">
                    <div className="border-r-3 border-[#3667E9] p-1.5 h-[100px] flex items-center">
                      <div className="text-xs text-gray-500">
                        {formatTime(item.appointment_time)}
                      </div>
                    </div>
                    <div className="w-[calc(100%-80px)]">
                      <div className="flex justify-between items-center">
                        <p className="font-bold text-nowrap">
                          {item.patient.first_name +
                            (item.patient.last_name
                              ? " " + item.patient.last_name
                              : "")}
                        </p>
                      </div>
                      {item.subject && (
                        <div className="font-medium text-sm text-gray-500">
                          {item.subject}
                        </div>
                      )}
                      <div className="text-sm text-gray-500">
                        {item.description}
                      </div>
                    </div>
                  </div>
                  {item.status === "processing" &&
                    hoveredItemId === item.id && (
                      <div className="bg-black text-white p-2 rounded">
                        {L.CONVERSATION_IS_UNDER_PROCESS}
                      </div>
                    )}
                  <div div className="flex space-x-4">
                    {item.status === "processing" && (
                      <AccessTimeIcon className="text-orange-500 !h-8 !w-8" />
                    )}
                    {sessionStorage.getItem(SUPER_USER) === "true" && (
                      <button
                        onClick={(e) => handleDeleteConfirmation(item.id, e)}
                      >
                        <DeleteIcon className="hover:text-red-600 !h-7 !w-7" />
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="h-full w-full text-base md:text-xl text-gray-500 font-bold opacity-70 flex items-center justify-center">
              {L.NO_CONSULTATION}
            </div>
          )}
        </div>
        <ConfirmationModal
          text="Are you sure you want to delete this appointment?"
          show={showModal}
          title="Confirm Deletion"
          handleClose={async (confirmed) => {
            setShowModal(false);
            if (confirmed && selectedId) {
              handleDeleteAppointment(selectedId);
            }
          }}
        />
      </div>
    );
  }
);


export default Agenda;
