// authService.js
import axios from "axios";
import { API } from "../constants/api.const";
import {
  NAME_KEY,
  SUPER_USER,
  TOKEN_KEY,
  USER_ID_KEY,
  REFRESH_TOKEN_KEY,
} from "../constants/key.const";
import { toast } from "react-toastify";

export const login = async (email, password, otpToken) => {
  try {
    const response = await axios.post(API.LOGIN, {
      email,
      password,
      otp_token: otpToken,
    });
    const { access, refresh, id, name, is_superuser } = response.data.data;

    sessionStorage.setItem(TOKEN_KEY, access);
    sessionStorage.setItem(REFRESH_TOKEN_KEY, refresh);
    sessionStorage.setItem(NAME_KEY, name);
    sessionStorage.setItem(USER_ID_KEY, id);
    sessionStorage.setItem(SUPER_USER, is_superuser);

    return { success: true, message: response.data.message };
  } catch (error) {
    toast.error(error.response?.data?.detail);
    return {
      success: false,
      message: "Login failed",
    };
  }
};

export const logout = () => {
  sessionStorage.clear();
};

export const getToken = () => {
  return sessionStorage.getItem(TOKEN_KEY);
};

export const getRefreshToken = () => {
  return sessionStorage.getItem(REFRESH_TOKEN_KEY);
};

export const isLoggedIn = () => {
  return !!sessionStorage.getItem(TOKEN_KEY);
};

export const checkEmail = async (email) => {
  try {
    const response = await axios.post(API.EMAIL_CHECK, {
      email,
    });
    console.log(response);
    return response.data.data.token;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

// Method to verify OTP
export const verifyOtp = async (otp, token) => {
  try {
    const response = await axios.post(API.VERIFY_OTP, {
      otp,
      token,
    });
    return response.data.data.token;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

// Method to reset password
export const resetPassword = async (token, password, confirmPassword) => {
  try {
    await axios.post(API.RESET_PASSWORD, {
      token,
      password,
      confirm_password: confirmPassword,
    });
    toast.success("Password reset successfull");
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

export const signup = async (data) => {
  try {
    const res = await axios.post(API.SIGNUP, data);
    return res.data;
  } catch (error) {
    toast.error(
      error.response.data.detail || "Something went wrong while signup"
    );
    console.error("Error while signup", error);
    throw new Error("Error while signup", error);
  }
};

export const verifyEmail = async (email, otp) => {
  try {
    const response = await axios.post(API.VERIFY_EMAIL, { email, otp });
    const { access, refresh, id, name, is_superuser } = response.data.data;
    sessionStorage.setItem(TOKEN_KEY, access);
    sessionStorage.setItem(REFRESH_TOKEN_KEY, refresh);
    sessionStorage.setItem(NAME_KEY, name);
    sessionStorage.setItem(USER_ID_KEY, id);
    sessionStorage.setItem(SUPER_USER, is_superuser);

    return true;
  } catch (error) {
    console.error(error);
    toast.error(
      error.response.data.detail || "Something went wrong while verifying email"
    );
    return false;
  }
};

export const resendOtp = async (email) => {
  try {
    await axios.post(API.RESEND_OTP, { email });
  } catch (error) {
    console.error("Error while resending otp", error);
  }
};
