import React, { useState, useEffect } from "react";
import { TextField, Button, MenuItem, Select } from "@mui/material";
import {
  LocalizationProvider,
  DateCalendar,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "../style/addAppointment.css"; // Import your custom CSS styles
import { createAppointment, getAllUsers } from "../services/userService";

const formatAppointmentData = (
  user,
  patientName,
  date,
  startTime,
  endTime,
  subject,
  description,
  gender,
  code
) => {
  const formattedDate = dayjs(date).format("YYYY-MM-DD");

  const formattedStartTime = dayjs(date)
    .hour(startTime.hour())
    .minute(startTime.minute())
    .second(0)
    .format("YYYY-MM-DDTHH:mm:ss[Z]");

  const formattedEndTime = dayjs(date)
    .hour(endTime.hour())
    .minute(endTime.minute())
    .second(0)
    .format("YYYY-MM-DDTHH:mm:ss[Z]");

  const appointmentData = {
    user_id: user,
    patient_name: patientName,
    date: formattedDate,
    start_time: formattedStartTime,
    end_time: formattedEndTime,
    subject,
    description,
    gender,
    client_code: code,
  };

  return appointmentData;
};

const AddAppointment = () => {
  const [users, setUsers] = useState([]);

  // Form state
  const [user, setUser] = useState("");
  const [patientName, setPatientName] = useState("");
  const [description, setDescription] = useState("");
  const [subject, setSubject] = useState("");
  const [date, setDate] = useState(dayjs());
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [gender, setGender] = useState("");
  const [code, setCode] = useState("");

  // Error state
  const [errors, setErrors] = useState({
    user: "",
    patientName: "",
    date: "",
    startTime: "",
    endTime: "",
    subject: "",
    description: "",
    gender: "",
    code: "",
  });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const res = await getAllUsers();
        setUsers(res);
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    };

    fetchUsers();
  }, []);

  // Validate fields before submitting
  const validate = () => {
    const newErrors = {};
    if (!user) newErrors.user = "Select a user";

    if (!patientName) newErrors.patientName = "Patient name is required";

    if (!date) newErrors.date = "Select a date";

    if (!subject) newErrors.subject = "Date of birth is required";

    if (!description) newErrors.description = "Description is required";

    if (!startTime) {
      newErrors.startTime = "Select start time";
    }

    if (!gender) newErrors.gender = "Select gender";

    if (!endTime) {
      newErrors.endTime = "Select end time";
    } else if (dayjs(endTime).isBefore(dayjs(startTime))) {
      newErrors.endTime = "End time must be after start time";
    }

    if (!code) newErrors.code = "Code is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }

    const res = formatAppointmentData(
      user,
      patientName,
      date,
      startTime,
      endTime,
      subject,
      description,
      gender,
      code
    );

    createAppointment(res);

    setUser("");
    setPatientName("");
    setSubject("");
    setDescription("");
    setDate(null);
    setStartTime(null);
    setEndTime(null);
    setCode("");
    setErrors({});
  };

  const handleUserChange = (event) => {
    setUser(event.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, user: "" }));
  };

  return (
    <section className="d-flex justify-content-center align-items-center appointment-container">
      <div className="px-4 py-4 card" style={{ width: "90%" }}>
        <h3 className="text-center mb-2">Add Appointment</h3>
        <form onSubmit={handleSubmit}>
          <div className="row g-3">
            {/* User Selection */}
            <div className="col-md-6 d-flex flex-column">
              <label className="form-label">Select User</label>
              <Select
                id="user-select"
                value={user}
                label="Select User"
                onChange={handleUserChange}
                error={Boolean(errors.user)}
                sx={{
                  borderRadius: "10px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                  },
                }}
              >
                {users?.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.user && (
                <div className="text-danger mt-2">{errors.user}</div>
              )}
            </div>

            {/* Gender Selection */}
            <div className="col-md-6 d-flex flex-column">
              <label className="form-label">Gender</label>
              <Select
                name="gender"
                value={gender}
                onChange={(e) => {
                  setGender(e.target.value);
                  setErrors((prevErrors) => ({ ...prevErrors, gender: "" }));
                }}
                error={Boolean(errors.gender)}
                sx={{ borderRadius: "10px" }}
              >
                <MenuItem value="">Select Gender</MenuItem>
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
              </Select>
              {errors.gender && (
                <div className="text-danger mt-2">{errors.gender}</div>
              )}
            </div>

            {/* Patient Name */}
            <div className="col-md-6">
              <label className="form-label">Patient Name</label>
              <TextField
                name="patientName"
                fullWidth
                variant="outlined"
                value={patientName}
                onChange={(e) => {
                  setPatientName(e.target.value);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    patientName: "",
                  }));
                }}
                error={Boolean(errors.patientName)}
                helperText={errors.patientName}
                sx={{
                  borderRadius: "10px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                  },
                }}
              />
            </div>

            {/* Subject */}
            <div className="col-md-6">
              <label className="form-label">Date of birth</label>
              <TextField
                name="subject"
                fullWidth
                variant="outlined"
                value={subject}
                onChange={(e) => {
                  setSubject(e.target.value);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    subject: "",
                  }));
                }}
                error={Boolean(errors.subject)}
                helperText={errors.subject}
                sx={{
                  borderRadius: "10px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                  },
                }}
              />
            </div>

            {/* Description */}
            <div className="col-12">
              <label className="form-label">Description</label>
              <TextField
                name="description"
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    description: "",
                  }));
                }}
                error={Boolean(errors.description)}
                helperText={errors.description}
                sx={{
                  borderRadius: "10px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                  },
                }}
              />
            </div>

            {/* Date and Time Fields */}
            <div className="col-md-6 mt-4">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                  className="calendar"
                  value={date}
                  onChange={(newDate) => {
                    setDate(newDate);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      date: "",
                    }));
                  }}
                />
              </LocalizationProvider>
              {errors.date && (
                <div className="text-danger mt-2">{errors.date}</div>
              )}
            </div>

            <div className="col-lg-6 d-flex flex-column mt-4">
              <div>
                <label className="form-label">Code</label>
                <TextField
                  name="code"
                  fullWidth
                  variant="outlined"
                  value={code}
                  onChange={(e) => {
                    setCode(e.target.value);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      code: "",
                    }));
                  }}
                  error={Boolean(errors.code)}
                  helperText={errors.code}
                  sx={{
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                    },
                  }}
                />
              </div>
              <div className="d-flex gap-3 mt-3">
                {/* Start Time */}
                <div className="w-50">
                  <label className="form-label">Start Time</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      ampm={false}
                      value={startTime}
                      onChange={(newValue) => {
                        setStartTime(newValue);
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          startTime: "",
                        }));
                      }}
                      sx={{
                        borderRadius: "10px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </LocalizationProvider>
                  {errors.startTime && (
                    <div className="text-danger mt-2">{errors.startTime}</div>
                  )}
                </div>

                {/* End Time */}
                <div className="w-50">
                  <label className="form-label">End Time</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      ampm={false}
                      value={endTime}
                      onChange={(newValue) => {
                        setEndTime(newValue);
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          endTime: "",
                        }));
                      }}
                      sx={{
                        borderRadius: "10px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </LocalizationProvider>
                  {errors.endTime && (
                    <div className="text-danger mt-2">{errors.endTime}</div>
                  )}
                </div>
              </div>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="mt-4"
              >
                Create Appointment
              </Button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default AddAppointment;
