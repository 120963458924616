import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

function ProtectedRoute({ children, adminOnly = false }) {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const isSuperUser = sessionStorage.getItem("SUPERUSER") === "true";

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/sign-in");
    } else if (adminOnly && !isSuperUser) {
      navigate("/home");
    }
  }, [isAuthenticated, isSuperUser, adminOnly, navigate]);

  return isAuthenticated && (!adminOnly || isSuperUser) ? children : null;
}

export default ProtectedRoute;
