import React, { useEffect, useState } from "react";
import "../style/auth.scss";
import * as formik from "formik";
import * as yup from "yup";
import Form from "react-bootstrap/Form";
import { NavLink, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import OTPInput from "react-otp-input";
import { signup } from "../services/authService";
import { useAuth } from "../contexts/AuthContext";
import { TOKEN_KEY } from "../constants/key.const";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const SignUp = () => {
  const { Formik } = formik;
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState(null);
  const { verifyEmailUser } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [terms, setTerms] = useState(false);
  const [termsError, setTermsError] = useState("");
  const [qrCodeUrl, setQrCodeUrl] = useState("");

  useEffect(() => {
    const token = sessionStorage.getItem(TOKEN_KEY);
    if (token) {
      navigate("/home");
    }
  }, [navigate]);

  const otpSchema = yup.object().shape({
    otp: yup
      .string()
      .length(6, "OTP must be exactly 6 digits")
      .required("OTP is required"),
  });

  const userSchema = yup.object().shape({
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    gender: yup.string().required("Gender is required"),
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        "Password must contain at least one special character"
      ) // At least one special character
      .required("New password is required"),
    confirm_password: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const handleSubmitData = async (data) => {
    if (!terms) {
      setTermsError("You must accept the terms and conditions");
      return;
    } else {
      setTermsError("");
    }

    try {
      const response = await signup(data); // Capture the response
      console.log(response);
      setQrCodeUrl(response.qr_code_url);
      setStep(2);
      setEmail(data.email);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOtpSubmit = async (data) => {
    console.log(data.otp, data.email);
    const res = await verifyEmailUser(data.email, data.otp);
    if (res) navigate("/");
  };

  return (
    <section className="main-auth">
      <div className="main-auth-form">
        {step === 1 ? (
          <>
            <h3 className="py-2">Sign Up</h3>
            <p className="mb-0 py-2">
              Already have an account?{" "}
              <NavLink to="/sign-in">&nbsp; Sign In</NavLink>
            </p>
            <Formik
              validationSchema={userSchema}
              onSubmit={handleSubmitData}
              initialValues={{
                first_name: "",
                last_name: "",
                email: "",
                gender: "",
                password: "",
                confirm_password: "",
              }}
              validateOnChange={false} // Disable validation on change
              validateOnBlur={false}
            >
              {({ handleSubmit, handleChange, values, touched, errors }) => (
                <Form
                  noValidate
                  onSubmit={handleSubmit}
                  className="mt-4"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  <Form.Group controlId="first_name" className="w-50 p-1">
                    <Form.Control
                      type="text"
                      placeholder="First Name"
                      name="first_name"
                      value={values.first_name}
                      onChange={handleChange}
                      isInvalid={!!errors.first_name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.first_name}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="last_name" className="w-50 p-1">
                    <Form.Control
                      type="text"
                      placeholder="Last Name"
                      name="last_name"
                      value={values.last_name}
                      onChange={handleChange}
                      isInvalid={!!errors.last_name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.last_name}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="email" className="w-100 p-1">
                    <Form.Control
                      type="email"
                      placeholder="Email address"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="gender" className="w-100 p-1">
                    <Form.Control
                      as="select"
                      name="gender"
                      value={values.gender}
                      onChange={handleChange}
                      isInvalid={!!errors.gender}
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.gender}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="password" className="w-100 p-1">
                    <div style={{ position: "relative" }}>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        isInvalid={!!errors.password}
                      />

                      <FontAwesomeIcon
                        onClick={() => setShowPassword(!showPassword)}
                        icon={showPassword ? faEyeSlash : faEye}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                          color: "#6c757d",
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>

                  <Form.Group
                    controlId="confirm_password"
                    className="w-100 p-1"
                  >
                    <div style={{ position: "relative" }}>
                      <Form.Control
                        type={showConfirmPassword ? "text" : "password"}
                        placeholder="Confirm Password"
                        name="confirm_password"
                        value={values.confirm_password}
                        onChange={handleChange}
                        isInvalid={!!errors.confirm_password}
                      />
                      <FontAwesomeIcon
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        icon={showConfirmPassword ? faEyeSlash : faEye}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                          color: "#6c757d",
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.confirm_password}
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>

                  <Button
                    type="submit"
                    variant="primary"
                    className="w-100 mt-4 auth-button"
                  >
                    Sign Up
                  </Button>

                  <hr />

                  <div className="message flex">
                    <input
                      type="checkbox"
                      value={terms}
                      onChange={(e) => setTerms(e.target.value)}
                    />
                    <div>
                      By clicking Create account, I agree that I have read and
                      accepted the Terms of Use and Privacy Policy.
                    </div>
                  </div>
                  {termsError && (
                    <div className="text-danger mt-1">{termsError}</div>
                  )}

                  <div className="message">
                    Protected by reCAPTCHA and subjected to{" "}
                    <NavLink>Privacy Policy</NavLink> and{" "}
                    <NavLink>Terms of Service</NavLink>.
                  </div>
                </Form>
              )}
            </Formik>
          </>
        ) : (
          <>
            <h3 className="py-2">Verify OTP</h3>
            <p className="mb-0 py-2">
              Please scan the QR code below with your authenticator app. An OTP
              will be sent to your email <strong>{email}</strong>.
              <img
                src={qrCodeUrl}
                alt="QR Code"
                style={{ display: "block", margin: "20px auto" }}
              />{" "}
              {/* If you didn’t receive the OTP,
              <span
                style={{ cursor: "pointer", color: "blue" }}
                onClick={() => resendOtp(email)}
              >
                &nbsp;Resend OTP
              </span>
              . */}
            </p>
            <Formik
              validationSchema={otpSchema}
              onSubmit={handleOtpSubmit}
              initialValues={{ otp: "" }}
            >
              {({ handleSubmit, values, errors, setFieldValue }) => (
                <Form
                  noValidate
                  onSubmit={handleSubmit}
                  className="mt-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <OTPInput
                    value={values.otp}
                    onChange={(otp) => setFieldValue("otp", otp)} // Update OTP value in Formik
                    numInputs={6} // Number of OTP input fields
                    renderSeparator={<span>{"\t"}</span>}
                    renderInput={(props) => (
                      <input
                        {...props}
                        style={{
                          width: "40px",
                          height: "40px",
                          margin: "0 5px",
                          textAlign: "center",
                          border: "2px solid black",
                        }}
                      />
                    )}
                  />
                  {errors.otp && (
                    <div className="text-danger mt-2">{errors.otp}</div>
                  )}
                  <Button
                    type="submit"
                    variant="primary"
                    className="w-100 mt-4"
                  >
                    Verify OTP
                  </Button>
                  <Button
                    variant="secondary"
                    className="w-100 mt-2"
                    onClick={() => setStep(1)}
                  >
                    Back to Sign Up
                  </Button>
                </Form>
              )}
            </Formik>
          </>
        )}
      </div>
    </section>
  );
};

export default SignUp;
