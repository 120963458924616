import React, { createContext, useState, useContext } from "react";
import {
  login,
  logout,
  isLoggedIn,
  verifyEmail,
} from "../services/authService";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(isLoggedIn());

  const loginUser = async (email, password, token) => {
    const result = await login(email, password, token);
    if (result.success) {
      setIsAuthenticated(true);
    }
    return result;
  };

  const verifyEmailUser = async (email, otp) => {
    console.log(email, otp);
    const result = await verifyEmail(email, otp);

    if (result) {
      setIsAuthenticated(true);
    }
    return result;
  };

  const logoutUser = () => {
    logout();
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, verifyEmailUser, loginUser, logoutUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};
