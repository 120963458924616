import React, { useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { NavLink, useNavigate } from "react-router-dom";
import OTPInput from "react-otp-input";
import { checkEmail, verifyOtp, resetPassword } from "../services/authService";
import WestIcon from "@mui/icons-material/West";

const ForgotPassword = () => {
  const [step, setStep] = useState(1); // Step 1: Enter email, Step 2: Enter OTP, Step 3: Reset Password
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [emailToken, setEmailToken] = useState(null);
  const [otpToken, setOtpToken] = useState(null);

  const emailSchema = yup.object().shape({
    email: yup.string().email().required("Email is required"),
  });

  const otpSchema = yup.object().shape({
    otp: yup
      .string()
      .length(4, "OTP must be exactly 4 digits")
      .required("OTP is required"),
  });

  // Step 3 schema: Validate new password and confirm password
  const passwordSchema = yup.object().shape({
    newPassword: yup
      .string()
      .min(6, "Password must be at least 6 characters")
      .required("New password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  // Step 1: Handle sending OTP
  const handleEmailSubmit = async (data, { resetForm }) => {
    setErrorMessage("");
    try {
      const token = await checkEmail(data.email); // Call API to send OTP
      setEmailToken(token);
      setStep(2);
      resetForm();
    } catch (error) {
      console.error(error);
      setErrorMessage(error.detail || "Failed to send OTP. Please try again.");
    }
  };

  // Step 2: Handle verifying OTP
  const handleOtpSubmit = async (data) => {
    setErrorMessage("");
    try {
      const token = await verifyOtp(data.otp, emailToken);
      setOtpToken(token);
      setStep(3);
    } catch (error) {
      setErrorMessage(error.detail || "Invalid OTP. Please try again.");
    }
  };

  // Step 3: Handle resetting password
  const handlePasswordSubmit = async (data) => {
    setErrorMessage("");
    try {
      await resetPassword(otpToken, data.newPassword, data.confirmPassword);
      navigate("/login");
    } catch (error) {
      setErrorMessage(
        error.detail || "Failed to reset password. Please try again."
      );
    }
  };

  return (
    <section className="main-auth">
      <div className="main-auth-form">
        {step === 1 ? (
          <>
            <h3>Forgot Password</h3>
            <Formik
              validationSchema={emailSchema}
              onSubmit={handleEmailSubmit}
              initialValues={{ email: "" }}
            >
              {({ handleSubmit, handleChange, values, errors }) => (
                <Form noValidate onSubmit={handleSubmit} className="mt-4 py-1">
                  <Form.Group controlId="email">
                    <Form.Control
                      type="email"
                      placeholder="Email address"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  {errorMessage && (
                    <div className="text-danger mt-3">{errorMessage}</div>
                  )}
                  <Button
                    type="submit"
                    variant="primary"
                    className="w-100 mt-4"
                  >
                    Send OTP
                  </Button>
                  <div className="mt-4 ">
                    <NavLink to="/sign-in" className="text-decoration-none">
                      <strong>
                        <WestIcon /> Back to Sign In
                      </strong>
                    </NavLink>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        ) : step === 2 ? (
          <>
            <h3>Enter OTP</h3>
            <Formik
              validationSchema={otpSchema}
              onSubmit={handleOtpSubmit}
              initialValues={{ otp: "" }}
            >
              {({ handleSubmit, values, errors, setFieldValue }) => (
                <Form noValidate onSubmit={handleSubmit} className="mt-4 py-1">
                  <OTPInput
                    value={values.otp}
                    onChange={(otp) => setFieldValue("otp", otp)}
                    numInputs={4}
                    renderSeparator={<span>{"\t"}</span>}
                    renderInput={(props) => (
                      <input
                        {...props}
                        style={{
                          width: "40px",
                          height: "40px",
                          margin: "0 5px",
                          textAlign: "center",
                        }}
                      />
                    )}
                  />
                  {errors.otp && (
                    <div className="text-danger mt-2">{errors.otp}</div>
                  )}
                  {errorMessage && (
                    <div className="text-danger mt-3">{errorMessage}</div>
                  )}
                  <Button
                    type="submit"
                    variant="primary"
                    className="w-100 mt-4"
                  >
                    Verify OTP
                  </Button>
                </Form>
              )}
            </Formik>
          </>
        ) : (
          <>
            <h3>Reset Password</h3>
            <Formik
              validationSchema={passwordSchema}
              onSubmit={handlePasswordSubmit}
              initialValues={{ newPassword: "", confirmPassword: "" }}
            >
              {({ handleSubmit, handleChange, values, errors }) => (
                <Form noValidate onSubmit={handleSubmit} className="mt-4 py-1">
                  <Form.Group controlId="newPassword">
                    <Form.Control
                      type="password"
                      placeholder="New Password"
                      name="newPassword"
                      value={values.newPassword}
                      onChange={handleChange}
                      isInvalid={!!errors.newPassword}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.newPassword}
                    </Form.Control.Feedback>
                  </Form.Group>

                  {/* Add margin for spacing */}
                  <div style={{ margin: "1rem 0" }}></div>

                  <Form.Group controlId="confirmPassword">
                    <Form.Control
                      type="password"
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      isInvalid={!!errors.confirmPassword}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.confirmPassword}
                    </Form.Control.Feedback>
                  </Form.Group>

                  {errorMessage && (
                    <div className="text-danger mt-3">{errorMessage}</div>
                  )}
                  <Button
                    type="submit"
                    variant="primary"
                    className="w-100 mt-4"
                  >
                    Reset Password
                  </Button>
                </Form>
              )}
            </Formik>
          </>
        )}
      </div>
    </section>
  );
};

export default ForgotPassword;
