// import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { L } from "../langauge/english.lang";
import logo from "../images/logo.png";
import Toggle from "./Toggle";

export function SlidingNav({
  links,
  privacyMode,
  setPrivacyMode,
  setPage,
  isOpen,
  setIsOpen,
}) {
  return (
    <>
      <div
        className={`fixed left-0 top-0 w-2/3 md:w-1/3 z-40 bg-white h-full border-r border-[#d1d1d1] lg:w-[300px] flex flex-col justify-between transition-transform duration-300 ease-in-out ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } xl:translate-x-0 xl:static`}
      >
        <div>
          <div className="flex justify-center">
            <img src={logo} alt="Logo" className="h-[86px] border-b-2" />
          </div>
          <div className="flex flex-col gap-4 mt-[50px] w-full p-[12px]">
            {links.map((link, i) => (
              <NavLink
                key={i}
                className={({ isActive }) =>
                  `rounded p-3 flex justify-start text-black gap-[13px] items-center ${
                    isActive
                      ? "bg-[#3666e913] text-[rgb(0,66,207)]"
                      : "text-gray-500"
                  }`
                }
                to={link.path}
                onClick={() => {
                  setPage(link.label);
                  setIsOpen(false);
                }}
              >
                <FontAwesomeIcon
                  icon={link.icon}
                  className={`text-[1.1em] ${({ isActive }) =>
                    isActive ? "text-[rgb(0,66,207)]" : "text-gray-500"}`}
                />
                {link.label}
              </NavLink>
            ))}
          </div>
        </div>
        <div className="p-[20px]">
          <Toggle
            label={L.PRIVACY_MODE}
            toggled={privacyMode}
            onClick={(e) => setPrivacyMode(e)}
          />
        </div>
      </div>

      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-30 xl:hidden"
          onClick={() => setIsOpen(false)}
        ></div>
      )}
    </>
  );
}
