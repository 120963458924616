import React, { useCallback, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import axios from "axios";
import { API, getAuthHeader } from "../constants/api.const";
import EditNoteIcon from "@mui/icons-material/EditNote";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import KeyboardVoiceOutlinedIcon from "@mui/icons-material/KeyboardVoiceOutlined";
import "../style/home.scss";
import { L } from "../langauge/english.lang";
import Agenda from "./Agenda";
import { toast } from "react-toastify";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ConfirmationModal from "../component/ConfirmationModal";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteConsultation } from "../services/dataService";
import { SUPER_USER } from "../constants/key.const";

const MainHome = ({ privacyMode, setFormData, setActiveStep }) => {
  const [toReview, setToReview] = useState([]);
  const [date, setDate] = useState(dayjs());
  const [agenda, setAgenda] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const formattedDate = dayjs(date).format("YYYY-MM-DD");
  const [selectedId, setSelectedId] = useState(null);

  const formatDate = (dateString, options) => {
    if (!dateString) return "";
    try {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat("en-In", options).format(date);
    } catch (e) {
      return "";
    }
  };

  const dt = new Date();
  const today = `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`;
  const fetchAgenda = useCallback(() => {
    axios
      .get(API.GET_APPOINTMENTS + `?is_scheduled=true&date=${formattedDate}`, {
        headers: getAuthHeader(),
      })
      .then((res) => {
        if (res && res.data) {
          setAgenda(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [formattedDate]);

  const fetchData = () => {
    axios
      .get(API.GET_RECORDINGS + `?is_reviewed=False`, {
        headers: getAuthHeader(),
      })
      .then((res) => {
        if (res && res.data) setToReview(res.data.data);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 30000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    fetchAgenda();
    const intervalId = setInterval(fetchAgenda, 30000);
    return () => clearInterval(intervalId);
  }, [formattedDate, fetchAgenda]);

  const navigate = useNavigate();

  const review = (recording) => {
    const apt = recording.appointment;
    setActiveStep(2);
    navigate("/home/walkthrough/review", {
      state: {
        apt: {
          id: apt.id,
          client_name:
            apt.patient.first_name +
            (apt.patient.last_name ? " " + apt.patient.last_name : ""),
        },
        recordingId: recording.id,
        consultation: recording.consultation,
      },
    });
  };

  const handleSubmitData = () => {
    const el = document.getElementById("clientNameInputField");
    const value = el.value;
    if (!value) {
      toast.error("Enter client name first");
      return;
    }
    console.log(value);
    axios
      .post(
        API.POST_APPOINTMENT,
        { client_name: value },
        {
          headers: getAuthHeader(),
        }
      )
      .then((res) => {
        console.log(res);
        if (res.status !== 200) {
          toast.error("Some error occurred while adding the client");
          return;
        }
        setActiveStep(1);
        navigate("/home/walkthrough/record", {
          state: { apt: { id: res?.data?.data?.id, client_name: value } },
        });
      })
      .catch((e) => {
        console.log(e);
        toast.error("Some error occurred while adding the client");
      });
  };
  const handleDeleteClick = (appointmentId, event) => {
    event.stopPropagation();
    setSelectedId(appointmentId);
    setShowModal(true);
  };
  const handleDeleteAppointment = async (appointmentId) => {
    await deleteConsultation(appointmentId);
    fetchData();
  };
  return (
    <div className="flex justify-center md:justify-normal w-full h-[calc(100vh-84px)] bg-[#F5F6F6] p-2.5 gap-2 md:gap-5 overflow-y-auto">
      <div className="w-1/2 min-w-[300px] flex flex-col gap-2.5 max-w-[95%]">
        <div className="hidden md:block bg-white rounded-xl px-7 w-full h-[calc(100%-160px)] overflow-x-auto relative">
          <div className="sticky top-0 pt-3 bg-white z-10">
            <h3 className="text-black text-xl font-bold border-b-2 border-[rgba(188,188,188,0.581)] pb-2.5">
              {L.TO_REVIEW}
            </h3>
          </div>
          <div className="mt-3 overflow-y-auto h-[60vh] flex flex-col gap-2">
            {toReview.map((item, index) => (
              <div
                key={index}
                className="rounded border border-[#f1f1f1] p-2 flex justify-between items-center cursor-pointer"
                onClick={() => review(item)}
              >
                <div>
                  <div className="font-bold text-[#161616]">
                    {privacyMode ? (
                      <div className="uppercase">
                        {item.appointment.patient.first_name[0]}
                        {item.appointment.patient.last_name?.[0] || ""}
                      </div>
                    ) : (
                      <div>
                        {item.appointment.patient.first_name}
                        {item.appointment.patient.last_name
                          ? " " + item.appointment.patient.last_name
                          : ""}
                      </div>
                    )}
                  </div>
                  <div className="text-xs text-[#a9a9a9] font-medium">
                    {formatDate(item.appointment.appointment_time, {
                      dateStyle: "medium",
                      timeStyle: "short",
                      hourCycle: "h23",
                    })}
                  </div>
                  <div className="text-xs text-[#a9a9a9] font-medium">
                    {formatDate(item.appointment.appointment_end_time, {
                      timeStyle: "short",
                      hourCycle: "h23",
                    })}
                  </div>
                </div>
                {sessionStorage.getItem(SUPER_USER) === "true" && (
                  <button
                    onClick={(e) => {
                      handleDeleteClick(item.id, e);
                    }}
                  >
                    <DeleteIcon className="hover:text-red-600 !h-7 !w-7" />
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className=" w-full md:hidden flex flex-col gap-1 h-full bg-white rounded-xl p-2.5">
          <Agenda
            privacyMode={privacyMode}
            selectedDate={formattedDate}
            agenda={agenda}
            refreshAgenda={fetchAgenda}
          />
        </div>

        <div className="bg-white rounded-xl px-7 w-full h-auto">
          <div className="sticky top-0 pt-3 bg-white z-10">
            <h3 className="text-black text-base md:text-xl font-bold border-b-2 border-[rgba(188,188,188,0.581)] pb-2.5">
              {L.UNPLANNED_CONTACT}
            </h3>
            <div className="text-xs">{L.NO_CLIENT}</div>
          </div>

          <div className="flex gap-5 items-center my-2.5 mx-2">
            <input
              className="border border-[#d3d3d3] p-3 rounded-lg text-darkblue w-[calc(100%-60px)] focus:outline-none focus:shadow-[0_0_12px_rgb(173,173,231)] placeholder-gray-500 placeholder:text-sm"
              id="clientNameInputField"
              placeholder={L.CLIENT_NAME}
            />
            <div
              className="rounded-full flex justify-center items-center bg-[rgba(180,180,180,0.374)] p-1.5 h-11 w-11 cursor-pointer text-[#3667E9]"
              onClick={handleSubmitData}
            >
              <KeyboardVoiceOutlinedIcon />
            </div>
          </div>
        </div>
      </div>

      <div className="md:w-1/2 flex flex-col gap-2 xl:gap-0">
        <div className="bg-white rounded-2xl hidden md:block xl:hidden w-full">
          <LocalizationProvider dateAdapter={AdapterDayjs} className="cal">
            <DateCalendar
              value={date}
              onChange={(newDate) => {
                setDate(newDate);
              }}
              className="cal"
            />
          </LocalizationProvider>
        </div>
        <div className="w-full hidden md:flex md:flex-col gap-3 md:gap-2.5 h-full bg-white rounded-xl p-2.5">
          <Agenda
            privacyMode={privacyMode}
            selectedDate={formattedDate}
            agenda={agenda}
            refreshAgenda={fetchAgenda}
          />
        </div>
      </div>

      <div className="max-w-[95%] h-min w-min bg-white rounded-2xl hidden xl:block">
        <LocalizationProvider dateAdapter={AdapterDayjs} className="cal">
          <DateCalendar
            value={date}
            onChange={(newDate) => {
              setDate(newDate);
            }}
            className="cal"
          />
        </LocalizationProvider>
      </div>
      <ConfirmationModal
        text="Are you sure you want to delete this appointment?"
        show={showModal}
        title="Confirm Deletion"
        handleClose={async (confirmed) => {
          setShowModal(false);
          if (confirmed && selectedId) {
            handleDeleteAppointment(selectedId);
          }
        }}
      />
    </div>
  );
};

export default MainHome;
